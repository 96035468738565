html,
body {
  font-size: 16px;
}


@font-face {
  font-family: 'MontRegular'; // El nombre de la fuente que deseas usar
  src: url('../../public/fonts/montSemi.otf') format('opentype'); // Ruta de la fuente OTF
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MontHeavy'; // El nombre de la fuente que deseas usar
  src: url('../../public/fonts/montBold.otf') format('opentype'); // Ruta de la fuente OTF
  font-style: normal;
}

@font-face {
  font-family: 'MontSemiBold'; // El nombre de la fuente que deseas usar
  src: url('../../public/fonts/montLight.otf') format('opentype'); // Ruta de la fuente OTF
  font-style: normal;
}

// Define una variable para la fuente
$MontRegular: 'MontRegular', sans-serif;
$MontSemiBold: 'MontSemiBold', sans-serif;
$MontHeavy: 'MontHeavy', sans-serif;

* {
  font-family: $MontSemiBold;
}

.font-semibold {
  font-family: $MontSemiBold;
}

.font-bold {
  font-family: $MontHeavy !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
.card-title,
.tarjeta-tarifa-item-service {
  font-family: $MontHeavy !important;
}

.font {
  &-heavy {
    font-family: $MontHeavy !important;
  }

  &-semi {
    font-family: $MontSemiBold !important;
  }
}

/* Colors */
$colorPrimary : #19467c;
$colorSecundaryText: #79CFE2;
$colorSecundary: rgba(223, 240, 255, 1);
$contrast: #252625;
$white: #ffffff;
$black: #000000;
$gray: #F1F1F1;
$green: #3BB68A;
$red: #E84225;
$blueContrast: #F2FAFC;


.color {
  &-primary {
    color: $colorPrimary;
  }

  &-secundary {
    color: $colorSecundary;
  }

  &-secundaryText {
    color: $colorSecundaryText;
  }

  &-secundaryAlt {
    color: rgb(223, 240, 255)
  }

  &-green {
    color: $green;
  }

  &-red {
    color: $red !important;
  }

  &-dark {
    color: black !important;
  }
}

.border {
  &-primary {
    /* border-color: $colorPrimary !important; */
    border-color: $colorPrimary !important;
  }

  &-secundary {
    border-color: $colorSecundary;
  }

  &-green {
    border-color: $green;
  }

  &-red {
    border-color: $red;
  }
}

.btn {
  font-weight: 800 !important;
  font-family: $MontHeavy !important;

  &-primary:hover {
    background-color: $colorPrimary;
    box-shadow: 0px 0px 5px $colorPrimary;
    cursor: pointer;
  }
}

.b-gray {
  background-color: $gray !important;
}

/* media */
$breakpoint-xs: 360px;
$breakpoint-sm: 767px;
$breakpoint-md: 1023px;
$breakpoint-lg: 1360px;

@mixin media($media) {
  @if $media==xs {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  }

  @else if $media==sm {
    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
      @content;
    }
  }

  @else if $media==md {
    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
      @content;
    }
  }

  @else if $media==lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  }
}

.banner {

  /* &-full {
    width: 100% !important;
    height: auto !important;
  } */

  &-icon {
    background-color: #F1F1F1;
    width: 70px;
    height: 70px;
    text-align: center !important;
    margin: 0px auto 10px;

    img {
      height: 50px;
      margin-top: 10px;
      padding: 5px;
    }
  }
}

.font {
  &-09 {
    font-size: 0.9rem;
  }
}

.informacionLegal {
  font-size: 12px;
}

.navbar-brand {
  padding: 0px;
}


/* container */
.container-fluid {
  overflow: hidden !important;
  //max-width: 1440px !important;
}

.container {
  &-menu {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    a {
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: $black;
    }
  }

  &-tarjeta {
    /* background-image: url('../../public/img/backgroud-container-card.png'); */
    /* padding-top: 50px; */
    background-repeat: no-repeat;
    background-position-x: center;

    &-blog {
      background-image: url('../../public/img/backgroud-container-blog.png');
      background-repeat: no-repeat;
      background-size: cover;

      .tarjeta-blog {
        min-height: 300px !important;
        //background-color: $colorPrimary;
        color: $black;
      }
    }

    &-pregunta {
      background-image: url('../../public/img/backgroud-container-pregunta.png');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &-header {
    background-color: white !important;
    font-weight: 600;

    @include media(lg) {
      height: 100px;
    }
  }

}

.text {
  &-title {
    font-weight: 800;
    color: $colorPrimary;
    line-height: normal;
    font-size: 1.8rem;
    margin-top: 40px;
    font-family: $MontHeavy !important;
  }
}

.btn {
  border: none;
  padding: 10px 20px;
  border-radius: 0px;
  font-family: $MontSemiBold;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 1;

  @include media(xs) {
    height: 50px;
    line-height: 2;
    width: 100% !important;
  }

  &:disabled {
    background-color: gray;
  }

  &-primary {
    background-color: $colorPrimary;
    color: $white;
    padding: 0px auto;

    img {
      height: 24px;
      width: 24px;
    }

    &-small {
      max-width: 60px !important;
      float: right;
      text-align: center;

      margin img {
        width: 15px;
        width: auto;
      }
    }
  }

  &-success {
    background-color: green;
    color: $white;
  }
}

.tarjeta {
  border-radius: 0px;
  background-color: $white;
  margin: 1.5rem auto;

  &-prestamo {
    .prestamo-header {
      img {
        height: 60px !important;
      }
    }
  }

  &-herramienta {
    &-luz {
      padding: 20px 0px 0px;
      min-height: 210px;

      .card-title {
        color: $colorPrimary;
        padding: 0px 15px;
      }
    }
  }

  &-blog {

    &-blog {
      .card {
        &-title {
          min-height: 70px !important;
        }

        &-text {
          height: 150px !important;
          overflow: hidden;
          margin-bottom: 10px;

          @include media(xs) {
            min-height: auto;
          }

          @include media(lg) {
            min-height: 150px !important;
          }
        }
      }
    }

    max-width: 400px;

    span {
      font-size: 0.8rem;
    }

    .card {
      &-title {
        font-size: 1.12rem;
      }

      &-text {
        font-size: 0.9rem;
      }

      &-footer {
        a {
          color: $black;
        }
      }
    }

    &-min {
      border: 1px solid $colorSecundary;
      background-image: url('../../public/img/backgroud-tarjeta-min.svg');
      background-size: auto;
      background-repeat: no-repeat;
      background-position: 100% -20px;
    }
  }

  &-footer {
    &-small {
      font-size: 0.65rem;
    }
  }

  &-lead {
    border-radius: 0px;

    .card-header {
      border-radius: 0px;
      background-color: $colorPrimary;
      color: $white;
      //font-family: Mont;
      font-size: 1.5rem;
      padding-top: 15px;
      height: 60px;
      font-weight: 800;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;

    }

  }

  &-tarifa {
    padding: 10px 15px 20px;
    fill: #FFF;
    stroke-width: 0.8px;
    stroke: #8E8E8E;

    &:hover {
      box-shadow: 1px 1px 2px rgb(221, 221, 221);

    }

    &-item {
      &-descripcion {
        border-radius: 13px;
        border: 1px solid $black;
        width: fit-content;
        padding: 2px 10px;
        text-align: left;
        font-size: 0.9rem;
      }

      &-lead {
        border-bottom: 1px solid black;
        margin: 20px auto;
      }

      &-service {
        background: #F1F1F1;
        width: 100%;
        height: 195px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

        @include media(xs) {
          height: 100px;
          margin-bottom: 15px;
        }

        &-small {
          height: 95px;
        }
      }

      &-streaming {
        background: #F1F1F1;
        width: 100%;
        border-radius: 0px !important;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 0.9rem;

        @include media(xs) {
          height: 100px;
          margin-bottom: 15px;
        }
      }

      &-alarma {
        background: #F1F1F1;
        width: 100%;
        border-radius: 0px !important;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        font-size: 0.9rem;

        @include media(xs) {
          height: 100px;
          margin-bottom: 15px;
        }
      }

      &-title {
        border-bottom: 1px solid #E9E9E9;
        margin-bottom: 15px;

        img {
          height: 45px;
          //padding: 7px;
        }

        @include media(xs) {
          padding-bottom: 13px !important;
        }
      }

      &-title-cupon {
        border-bottom: 1px solid #E9E9E9;
        margin-bottom: 5px;

        img {
          height: 70px;
        }

        @include media(xs) {
          padding-bottom: 13px !important;
        }
      }
    }

    &-streaming {
      padding: 10px 15px 20px;
      fill: #FFF;
      stroke-width: 0.8px;
      stroke: #8E8E8E;

      &-header {
        height: 130px;
      }

      &-content {
        height: 240px;
      }

      &-item {
        background: #F1F1F1;
        width: 100%;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;

        @include media(xs) {
          height: 100px;
          margin-bottom: 15px;
        }

        &-title {
          border-bottom: 1px solid #E9E9E9;
          margin-bottom: 15px;
          padding: 20px;

          img {
            height: 35px !important;
          }

          @include media(xs) {
            margin-bottom: 5px;
          }
        }
      }
    }

    &-alarma {
      padding: 10px 15px 20px;
      fill: #FFF;
      stroke-width: 0.8px;
      stroke: #8E8E8E;
      overflow: hidden;

      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &-price {
        background-color: #f1f1f1;
      }
    }
  }

  .card-img,
  .card-img-top {
    border-radius: 0px;
  }

  &-producto {
    border: none;
    box-shadow: 0px 0px 5px lightgray;
    text-align: center;
    padding: 10px;
    margin: 0 auto;

    .card {
      &-title {
        color: $colorPrimary;
        font-weight: 600;
        font-size: 1rem;
        //height: 60px;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-body {
        ul {
          list-style-type: none;
          padding: 0px;
          height: 90px;

          li {
            text-align: center;
            //font-family: Mont;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 5px;
          }
        }
      }

      &-icon {
        background-color: rgba(121, 207, 226, 0.19);
        margin: 0 auto;
        margin-top: 40px;
        width: auto;
        max-height: 60px !important;
        text-align: center;
        padding: 15px;

        img {
          text-align: center;
          width: 30px;
        }
      }
    }
  }
}

footer {
  font-size: 0.9rem;

  @include media(xs) {
    font-size: 0.8rem;
  }

  .footer {
    &-img {
      &-mobile {
        height: 34px;
        width: 124px;
        text-align: center;
      }
    }

    &-copyright {
      font-size: 0.7rem;
    }
  }

  a {
    text-decoration: none;
    color: $white;
  }

  p {
    text-align: center;
    margin: 0;
    padding: 20px 0px;
  }

  ul {
    list-style: none;
    //margin-left: -30px;
    color: $white;
  }

  h5 {
    color: $white;
  }

}

.card {
  &-img {
    &-overlay {
      h1 {
        position: relative;
        bottom: 10px;
      }
    }
  }
}

.form {
  &-check {
    margin-top: 20px;

    &-label {
      color: #000;
      //font-family: Mont;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &-dark {
      .form-check-input {
        background-color: $black !important;
        border: 1px solid $black !important;

        &:checked {
          background-color: $black !important;
          border: 1px solid $black !important;
        }
      }
    }
  }

  &-input {
    border-radius: 0px;
  }
}

.input {
  &-check {
    a {
      color: black !important;
    }

    &-label {
      color: #000;
      //font-family: Mont;
      font-size: 0.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

    }

    &-dark {
      .form-check-input {
        border: 1px solid $black !important;

        &:checked {
          background-color: $black !important;
          border: 1px solid $black !important;
        }

        &:focus {
          box-shadow: 0px 0px 3px $black;
        }
      }
    }
  }
}

.link {
  &-politicy {
    color: $black;
    //font-family: Mont;
    margin-top: 10px;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: right;
  }
}

.interSeccion {
  height: 80px !important;

  @include media(xs) {
    height: 20px !important;
  }
}


.breadcrumb {
  &-item {
    a {
      color: $black;
      text-decoration: none;
    }
  }
}

.filtro {
  &-producto {
    &-logo {
      cursor: pointer;
      border: 0.8px solid #8E8E8E;
      background: #FFF;
      padding: 5px 10px;
      text-align: center;
      filter: grayscale(1);
      height: 50px;
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        filter: grayscale(0);
      }

      img {
        //height: 100%;
        width: 100%;
        padding: 10px;
      }

    }
    &-logo-vehiculo {
      cursor: pointer;
      border: 0.8px solid #8E8E8E;
      background: #FFF;
      padding: 5px 10px;
      text-align: center;
      filter: grayscale(1);
      width: 100%;
      overflow: hidden;

      &:hover {
        filter: grayscale(0);
      }

      img {
        width: 70px;
      }

    }
  }
}

.rc-slider {
  &-handle {
    border: 2px solid black !important;
    background-color: black !important;
  }

  &-track {
    background-color: black !important;
  }
}


.text {
  &-left {
    text-align: left;
  }
}


.acordion {
  &-icon {
    &-title {
      margin-right: 10px !important;
    }
  }
}


.carrusel {
  &-img {
    width: 100vw;
  }

  &-caption {
    text-shadow: 0px 0px 6px $black;

    a {
      font-family: $MontHeavy;
      color: $white;
    }
  }
}

.text {
  &-promotion {
    font-family: $MontHeavy;
    margin-left: 10px;
    @include media(xs) {
      margin-top: 0px;
    }
  }
}

.align-middle {
  vertical-align: middle !important;
}

.border {
  &-bottom {
    border-bottom: 1px solid $black;
  }
}

.text {
  &-primary {
    color: $colorPrimary !important;
  }
}

.input-group-text {
  background-color: white;
  border-radius: 0px !important;
}

.no {
  &-radius {
    border-radius: 0px !important;
  }

  &-border {
    &-bottom {
      border-bottom: 0px solid transparent !important;
    }
  }
}

.dropdown {
  &-menu {
    border-top: 5px solid $black;
    border-radius: 0px;
    margin-top: 0px;
  }

  .nav-link {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  &-item {
    margin: 10px 0px;
  }
}

.dropdown-toggle::after {
  display: none;
}

.subtitle {
  //font-size: 1rem;
  line-height: 1.5;
  font-family: $MontSemiBold;
}

.br-black {
  border-right: 2px solid black;
}

.bg {
  &-primary {
    background-color: $colorPrimary !important;
  }

  &-gray {
    background-color: $gray !important;
  }

  &-notInfo {
    background-color: #79CFE230;
    color: $colorPrimary;
  }

  &-gray {
    background-color: $gray;
    color: $colorPrimary;
  }

  &-blueContrast {
    background-color: $blueContrast;
  }

  &-secundary {
    background-color: $colorSecundary;
  }

  &-secundaryAlt {
    background-color: rgb(223, 240, 255);
  }

  &-secundaryText {
    background-color: $colorSecundaryText;
  }

  &-secundary-10 {
    background-color: rgba(242, 250, 252, 10);
  }

  &-secundary-contratacion {
    background-color: #79CFE226;
  }


}

.vertical {
  &-items {
    div {
      font-size: 0.8rem;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}

.dropdown:hover .dropdown-menu {
  display: block !important;
  background-color: white;
}

.img-banner {
  //max-height: 400px !important;
  width: auto !important;
}

.navbar-nav {
  div {
    @include media(xs) {
      width: 95vw;
      //border-bottom: 1px solid gray;
    }
  }
}

.logoFocus {
  filter: grayscale(0) !important;
  border-bottom: 5px solid black !important;
}

.carousel-control {
  &-next {
    opacity: 1;

    &-icon {
      display: none;
      background-image: url('../../public/img/icons/right.svg');
    }
  }

  &-prev {
    opacity: 1;

    &-icon {
      display: none;
      background-image: url('../../public/img/icons/left.svg');
    }
  }
}

.h {
  &-40 {
    height: 65px !important;
  }
}

.w {
  &-60 {
    width: 60px;
  }
}

.large {
  height: 450px;
}

.accordion-icon-title {
  @include media(xs) {
    margin-right: 15px;
  }
}

.load {
  width: 150px;
  height: auto;
  padding: 10px;
}

tr,
th {
  font-family: $MontSemiBold !important;
}

.pregunta-frecuente {
  &-item {
    border-radius: 20px !important;
    margin-bottom: 15px;
    border: 0px solid transparent;
    box-shadow: 0px 0px 10px $gray;
  }

  &-header {
    button {
      padding: 10px;
      border-radius: 20px !important;
      background-color: white;
      box-shadow: none;
      font-family: $MontSemiBold;
      font-weight: bolder;
    }
  }
}

.accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: none;
  color: black !important;
}

.accordion-button::after {
  background-image: url('../../public/img/icons/flechaAcordionDown.svg');

}

.accordion-button:not(.collapsed)::after {
  background-image: url('../../public/img/icons/flechaAcordionUp.svg')
}

.shadow {
  box-shadow: 0px 0px 10px #19467C1A !important;
  border: 0px solid transparent;
}

.listaAlternativa {
  li {
    list-style-type: none !important;
    padding-left: 20px;
    color: $colorSecundary;
    background-image: url('../../public/img/icons/circleli.svg');
    background-position: 0 6px;
    background-size: 10px;
    background-repeat: no-repeat;
    text-align: left !important;

    p {
      color: $black;
    }

    h5 {
      color: $colorPrimary;
    }
  }
}

.header-img-card {
  max-height: 50px !important;
  text-align: center !important;

  img {
    max-height: 70px !important;
    min-height: 70px !important;

    @include media(xs) {
      max-height: 40px !important;
      min-height: 40px !important;
      text-align: center !important;
      vertical-align: middle !important;

    }
  }
}

/* ul {
  list-style-type: none;
  padding: 0px;
  //height: 90px;

  li {
    text-align: center;
    //font-family: Mont;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
  }
} */

.no-enlace {
  color: $black;
  text-decoration: none;
}

.h-categorias {
  height: 320px !important;
}

.blog {
  h2 {
    color: $colorPrimary;
  }

  a {
    color: $colorPrimary !important;
    text-decoration: none;
    font-family: $MontSemiBold;
  }
}

.text-left {
  text-align: left !important;
}


.page-link {
  color: $colorPrimary !important;
  outline: 0px solid transparent !important;
}

.page-link.active,
.active>.page-link {
  background-color: $colorPrimary;
  color: white !important;
}

.card-footer:last-child {
  border-radius: 0px !important;
}

.grill_data {
  display: none !important;
}

.title-card-a {
  color: black !important;
}

.nav-item,
.no-link {
  text-decoration: none !important;
  margin: 0px;

}

.icon-list {

  ul,
  li {
    list-style: disc !important;
    margin-left: 15px;
    text-align: left !important;
    margin: 10px 0px;
  }

  list-style: disc !important;
  margin-left: 15px;
}

.submenu {
  display: none;
  position: absolute;
  background-color: #fff;
  border-top: 3px solid black;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  text-align: left !important;

  .dropdown-item {
    padding: 10px 20px;

    &:hover {
      background-color: #f9f9f9;
    }
  }
}

.nav-item:hover .submenu {
  display: block;
}

.stack {
  &-raices {
    background-color: $white;
    box-shadow: 0px 0px 6px #cfcece;
    display: flex;
    align-items: center;

    &-title {
      background-color: #79CFE230;
      margin: 0px 10px 0px 0px;
      width: 50px;
      height: 50px !important;
      text-align: center;

      img {
        vertical-align: text-top !important;
        width: 40px;
      }
    }
  }
}

.icon-full {
  width: 45px !important;
}

.carrusel-blog {
  height: 350px !important;
}

.carrusel-blog-vehiculo {
  width: 100%;
  height: 180px !important;
  background-color: black;
  background-size: both;
  overflow: hidden;
  margin-bottom: 10px;

  img {
    width: 100%;

    @include media(xs) {
      width: 100vw;
    }
  }
}

.carrusel-blog-vehiculo-landing {
  width: 100%;
  height: 500px !important;

  @include media(md) {
    height: 400px !important;
  }

  background-size: both;
  overflow: hidden;
  margin-bottom: 10px;

  img {
    width: 100%;

    @include media(xs) {
      width: 100vw;
    }
  }
}

.title {
  &-404 {
    font-size: 8rem !important;
    color: $colorPrimary !important;
  }
}

.prioridad {
  border: 3px solid $colorSecundary;

  &-oferta {
    top: 8px;
    background-color: $colorSecundary;
    color: black;
    width: 200px;
    height: 36px;
    position: relative;
    //top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 1rem;
    font-family: $MontHeavy !important;
  }

  &-oferta-prestamo {
    background-color: $colorPrimary;
    color: $white;
    width: 100%;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-size: 1rem;
    font-family: $MontSemiBold;
    margin-bottom: 5px;
  }
}

.no-cursor-link {
  cursor: default !important;

  a {
    cursor: default !important;
  }
}

p {
  a {
    font-family: $MontHeavy !important;
  }
}

.react-multiple-carousel__arrow {
  background-color: rgba(0, 0, 0, 0.3);

  &--left {
    left: 0px !important;
  }

  &--right {
    right: 0px !important;
  }
}

#rcc-confirm-button {
  background-color: $colorSecundary !important;
}

#cm__title {
  font-size: 0.9rem !important;
}

.w-100px {
  max-height: 150px !important;
  height: 150px;
  overflow: hidden;
}


.info-card {
  &-imagen {
    display: flex;
    justify-content: center;
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      border-radius: 0px !important;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;

    a {
      text-decoration: none;
      color: $black;
      justify-self: center;
    }
  }

  .vermas {
    display: block;
    align-items: start !important;
    justify-content: left !important;
  }

  .card-footer {
    background-color: white;
    border-top: 0px solid white;

    a {
      text-decoration: none;
      color: $black;
    }
  }

}

.banner-precio {
  background-color: $gray;
  text-align: center;
  font-size: 2rem;
  font-family: $MontHeavy;
  padding: 5px 0px;
}

.text-left-list {

  ul,
  li {
    text-align: left !important;
  }
}

.v-100 {
  width: 100% !important;
}

.img-vehiculos {
  /* width: 100%; */
  height: 180px;

  &-logo {
    width: 50px;
    max-width: 50px;
    background-color: white;
    margin-right: 10px;
  }
}

.icon-acordion-vehiculo {
  height: 30px;
  margin-right: 10px;
}

.ItemSelect {
  text-transform: capitalize;
  padding: 2px 3px;
}

.rc-slider-handle {
  opacity: 0 !important;
}

.descuento {
  text-align: right;
  font-size: 1rem;
  font-family: $MontHeavy;
  color: $colorSecundaryText;

  &-cupon {
    font-size: 1.5rem;
  }
}


.modal {
  &-cupon {
    &-banner {
      text-align: center;
      background-color: $colorPrimary;
      color: $white;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $colorSecundaryText;
        width: 70px;
        height: 70px;

        img {
          width: 40px;
        }
      }

      &-title {
        font-size: 1.5rem;
        margin-top: 20px;
        color: $colorSecundary;
        font-family: $MontSemiBold;
        width: 70%;
        line-height: 1.3;
      }
    }

    &-header {
      background-color: $colorPrimary;
      border: 0px transparent;
      border-radius: 0px;
    }
  }
}

.btn-close {
  color: $white;
}

.btn-close {
  background-color: url('../../public/img/icons/close.png') !important;
  --bs-btn-close-bg: url('../../public/img/icons/close.png') !important;
  opacity: 1;
}

.textoOpcional {
  p {

    strong,
    b {
      font-family: $MontSemiBold;
    }
  }
}

.btn-large {
  width: 100%;
}

.slider-icons-marcas {
  background-color: $gray;
  padding: 30px;
  border-radius: 36px;

  .slider-icons-marcas_img {
    height: 50px;
  }

}

.br {
  &-36 {
    border-radius: 36px;
  }
}

.vuskoo-eleccion {
  &-number {
    font-size: 2.5rem;
    color: $colorSecundaryText;
    font-family: $MontSemiBold;

  }

  &-title {
    color: $colorPrimary;
    font-size: 1.2rem;
    font-family: $MontHeavy !important;

    @include media(xs) {
      text-align: left;
    }
  }

  &-content {
    font-size: 0.95rem;
    text-align: right;

    @include media(xs) {
      text-align: left;
    }

    margin-top: 10px;
  }
}

.seccion-explorar {
  background-image: url('../../public/img/lineaDerechaBannerInferior.svg');
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;

}

.h {
  &-500 {
    height: 550px !important;
  }
}

.icon-logo-tarifa {
  height: 15px !important;
  margin-left: 5px;
}

.mt-7px {
  margin-top: 7px;
}

b {
  font-family: $MontHeavy !important;
}

.prestamo-informacion {
  background-color: rgb(223, 240, 255);
  background-size: contain;
  padding: 5px 10px;
  height: 100%;
}

.card-banner-seguros {
  background-color: $white;
  border-radius: 54px;
  margin: 20px auto;
  padding: 15px;
  border: 0px solid transparent;
  box-shadow: 0px 2px 10px #19467C1A;
  display: flex;
  flex-direction: row;

  &-img {
    margin: 15px auto 5px;
    border-radius: 50%;
  }
}

.border-seguros {
  padding: 20px;
  border-left: 5px solid $colorSecundaryText;
  margin: 20px auto;
  line-height: 1;
}

.icon-seguro {
  img {
    height: 44px;
  }
}

.tarjeta-info-logo-texto {
  min-height: 400px;
  border-radius: 45px;
  border: 0px solid transparent;
  box-shadow: 0px 0px 10px #19467C1A;

  img {
    width: 70px;
  }
}

.tabla-comparativa-costos {
  overflow: auto;
  background-color: $blueContrast;
  border-radius: 41px;
  border: 0px solid transparent;

  table {
    td {
      border-left: 5px solid $colorSecundaryText;
      padding: 10px 0px 10px 20px;
    }

    th {
      font-family: $MontHeavy !important;
    }
  }
}

.table> :not(caption)>*>* {
  background-color: transparent !important;
}

.icon-call {
  width: 17px !important;
  margin-top: 5px;
  
  @include media(xs) {
    padding: 0 !important;
    margin-top: 0px !important;
  }

  &-number {
    background-color: $colorPrimary;
    color: $white;
    padding: 10px;
    border-radius: 15px;
    font-family: $MontHeavy;
    font-size: 14px;
  }

  &-title {
    font-family: $MontSemiBold;
    margin-top: 10px;
    font-size: 14px;

    @include media(xs) {
      margin-top: 0px !important;
    }
  }
}

.footer-call-me{
  background-color: $colorSecundaryText;
  color: $white;
  height: 65px;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  font-family: $MontHeavy;
  font-size: 1.2rem;
}

.img-logo-tarjeta{
  height: 45px !important;
}

.button-with-hidden-text {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-weight: bold;
  color: #fff;
  background-color: #7093BC;
  border: none;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.button-with-hidden-text .text {
  position: relative;
  z-index: 1; /* Asegura que el texto esté encima */
  white-space: nowrap; /* Evita que el texto se divida en dos líneas */
}

.button-with-hidden-text .overlay {
  position: absolute;
  transform: rotate(145deg);
  top: 0;
  left: -64px;
  width: 100%; /* Cubre la mitad del botón */
  height: 200px;
  background-color: #19467C; /* Color de la superposición */
  z-index: 2; /* Asegura que la superposición esté encima del texto */
}

.accordion-button:not(.collapsed)::after{
  transform: none !important;
}

#tabs_filtros-tab-empresariales, #tabs_filtros-tab-particulares{
  background-color: white;
  color: $colorPrimary;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-around;
}

#tabs_filtros-tab-empresariales.nav-link.active{
    background-color: $colorPrimary;
    color: $white;
  }

  #tabs_filtros-tab-particulares.nav-link.active{
    background-color: $colorPrimary;
    color: $white;
  }

  .blog-image-container {
    margin-bottom: 20px;
    text-align: center; /* Centra la imagen */
  }
  
  .blog-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* Opcional: bordes redondeados */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Opcional: sombra para destacar */
  }
  
  /* Estilos para dispositivos móviles */
  @media (max-width: 768px) {
    .blog-image {
      max-height: 300px; /* Opcional: limitar altura en pantallas pequeñas */
      object-fit: cover; /* Asegura que la imagen se recorte correctamente */
    }
  }

  
  .text-with-image {
    position: relative;
  }
  
  .embedded-image {
    float: left; /* Flota la imagen a la izquierda */
    margin: 0 2em 2em 0; /* Margen para separar el texto */
    max-width: 40%; /* Ajusta el tamaño máximo de la imagen */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 8px; /* Opcional: bordes redondeados */
  }
  
  @media (max-width: 768px) {
    .embedded-image {
      float: none; /* Desactiva el flotado en dispositivos pequeños */
      margin: 0 auto 15px auto; /* Centra la imagen en pantallas pequeñas */
      display: block; /* Asegura que se renderice correctamente */
      max-width: 100%; /* La imagen ocupa el ancho completo */
    }
  }
  